<template lang="pug">
	main#faleConosco
		Banner(:contents="banner")
		Blocks(:contents="contents" :path.sync="path")
</template>

<script>
import Banner from '@sections/Pagina/Banner/Banner'
import Blocks from '@sections/FaleConosco/Blocks/Blocks'

export default {
	name: "view-fale-conosco",
	components:{
		Banner,
		Blocks,
	},
	data(){
		return {
			banner: {
				banner: {
					alt: `Trabalhe Conosco`,
					url: require(`@images/paginas/banner-fale-conosco.jpg`),
				},
				title: `Fale conosco`
			},
			contents: [],
			path: [],
		}
	},
	metaInfo() {
		return {
			title: 'Fale Conosco',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	mounted(){
		this.loadContact()
		if (this.$route.query.path) {
			this.path = this.$route.query.path.split(',').map(item => +item)
		}
	},
	methods: {
		loadContact() {
			this.$axios.get('contact-blocks')
				.then(response => {
					this.contents = response.data.contact
				}).catch(error => {
					console.error(error)
				})
		}
	}
}
</script>

<style lang="stylus" scoped src="./FaleConosco.styl"></style>
